import React from 'react';
import { createRoot } from 'react-dom/client';
import './styles/scss/main.scss';

const container = document.getElementById('root')!;
const root = createRoot(container);

// eslint-disable-next-line
const consoleError = console.error.bind(console);
// eslint-disable-next-line
console.error = (message, ...args) => {
  if (
    message?.code === 'MISSING_TRANSLATION' ||
    message === '%c%o' ||
    args.join(' ').includes('SmartIcon')
  )
    return;
  consoleError(message, ...args);
};

(async () => {
  try {
    const res = await fetch('/config');
    const data = await res.json();

    Object.entries(data).forEach(([key, value]) => {
      if (typeof value === 'string') {
        try {
          // @ts-ignore
          window[key] = JSON.parse(value);
        } catch (e) {
          // @ts-ignore
          window[key] = value;
        }
      } else {
        // @ts-ignore
        window[key] = value;
      }
    });

    const App = React.lazy(() => import('./App'));
    root.render(<App />);
  } catch (e) {
    root.render(<div>Internal Server Error</div>);
  }
})();
